@use '../../styles/utils' as *;

.bannerContainer {
  background-color: $color-yellow;
  padding: rem(12) 0;
}

.bannerContent {
  color: $color-slate;
  font-family: $body-font-family;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: rem(24);
  padding-bottom: rem(12);

  @include breakpoint-lg {
    padding-bottom: 0;
  }
}
