@use '../../../../styles/utils' as *;

.mobileMenuAccordion {
  @include font-size(20 32);

  font-family: $heading-secondary-font-family;
  font-style: italic;
  font-weight: 500;
  letter-spacing: -0.01em;
  width: 100%;
}

.mobileMenuAccordionButton {
  @include animated-accordion-button(90deg);

  align-items: center;
  border-top: 2px solid rgba($color-white, 24%);
  display: flex;
  justify-content: space-between;
  padding: rem(12) 0 rem(14);

  button {
    &:hover,
    &:focus {
      background-color: $color-slate;
      border-color: $color-white;
      fill: $color-white;
    }
  }
}

.mobileMenuAccordionFirstButton {
  border-top: 0 none;
}

.mobileMenuAccordionItemPanel {
  @include animated-accordion-panel;
}
