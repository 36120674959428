@use '../../../styles/utils/' as *;
@include button-classes;

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.borderless {
  border-color: transparent;
}

.outlineHover:hover {
  background-color: rgba($color-slate, 0.08);
  border-color: $color-slate;
  fill: $color-slate;
}

.borderless.outlineHover:hover {
  border-color: transparent;
}

.disabled {
  border-color: rgba($color-slate, 0.24);
  color: rgba($color-slate, 0.24);
  cursor: auto;
  fill: rgba($color-slate, 0.24);
  pointer-events: none;
}

.bounceIcon {
  animation: bounce-vertical 8s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite both;
}

@keyframes bounce-vertical {
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-3px);
  }

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(3px);
  }
}
