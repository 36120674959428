@use '../../styles/utils' as *;

.videoOverlay {
  align-items: center;
  background-color: $color-slate;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
}

.videoContent {
  background: none;
  border: 0;
  border-radius: 0;
  height: fit-content;
  inset: 0;
  overflow: hidden;
  padding: 0;
  position: unset;
  width: 98%;

  @include breakpoint-md {
    width: 85%;
  }

  @include breakpoint-xl {
    width: 75%;
  }
}

.closeButton {
  position: fixed;
  right: rem(24);
  top: rem(24);
}
