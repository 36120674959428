@use '../../styles/utils' as *;

.richTextHyperlink {
  color: $color-blue;

  path {
    fill: $color-blue;
  }

  &:hover {
    border-color: $color-slate;
    color: $color-slate;
    text-decoration: underline;

    path {
      fill: $color-slate;
    }
  }

  &Slate {
    color: $color-slate;

    path {
      fill: $color-slate;
    }
  }
}

.iconWrapper {
  display: inline-flex;
  height: rem(12);
  margin: 0 rem(4) rem(4) rem(4);
  vertical-align: middle;
  width: rem(12);
}
