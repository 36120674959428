@use '../../../styles/utils' as *;
@use 'sass:math';

.row {
  @include row-classes;

  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-(math.div($grid-gutter-horizontal-mobile, 2)));

  @include breakpoint-lg {
    margin: 0 rem(-(math.div($grid-gutter-horizontal-desktop, 2)));
  }
}
