@use '../../../styles/utils' as *;

$mask-width: 230px;

.mobileMenu {
  @include transition-ease-out(transform);

  background-color: $color-slate;
  color: $color-white;
  height: 100vh;
  position: fixed;
  top: 0;
  transform: translateX(200vw);
  width: 100%;
  z-index: 20;

  @include breakpoint-md {
    transform: translateX(calc(100vw + $mask-width));
    width: calc(100vw - $mask-width);
  }
}

.mobileMenuMask {
  @include transition-linear(opacity);

  background: linear-gradient(
    90deg,
    rgba($color-white, 50%) 0%,
    rgba($color-white, 50%) 50%,
    $color-slate 50%,
    $color-slate 100%
  );
  cursor: pointer;
  height: 120vh;
  opacity: 0;
  position: fixed;
  z-index: -1;
}

.mobileMenuMaskShow {
  bottom: 0;
  left: 0;
  opacity: 1;
  right: 0;
  top: 0;
  z-index: 19;
}

.mobileMenuInner {
  box-sizing: border-box;
  height: 90vh;
  overflow-y: scroll;
  padding: rem(26) rem(24);

  @include breakpoint-md {
    padding: rem(26) rem(64);
  }
}

.mobileMenuOpen {
  display: block;
  transform: translateX(0%);

  @include breakpoint-md {
    transform: translateX(230px);
  }

  @include breakpoint-lg {
    opacity: 0;
    z-index: -1;
  }
}

.mobileMenuTopBar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: rem(24);

  button {
    &:hover,
    &:focus {
      background-color: rgba($color-white, 8%);
      fill: $color-white;
    }
  }
}

.mobileMenuControls {
  display: flex;
  justify-content: space-between;
  padding-bottom: rem(24);
}
