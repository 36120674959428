@use '../../../styles/utils' as *;
@use 'sass:math';

.container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 rem($grid-gutter-horizontal-mobile);
  position: relative;
  width: 100%;

  @include breakpoint-md {
    padding: 0 rem($grid-gutter-horizontal-tablet);
  }

  @include breakpoint-lg {
    padding: 0 rem($grid-gutter-horizontal-desktop);
  }

  @include breakpoint-xl {
    max-width: rem($breakpoint-xl);
    padding: 0 rem($grid-gutter-horizontal-desktop);
  }

  @include breakpoint-xxl {
    max-width: rem($breakpoint-xxl);
    padding: 0 rem($grid-gutter-horizontal-desktop);
  }
}

.fixed {
  @include breakpoint-xl {
    max-width: rem($grid-container-xl);
  }

  @include breakpoint-xxl {
    max-width: rem($breakpoint-xxl);
  }
}

.full {
  @include breakpoint-xl {
    max-width: 100%;
  }

  @include breakpoint-xxl {
    max-width: 100%;
  }

  @include breakpoint-xxxl {
    max-width: 100%;
  }
}

.withThemeBackground {
  @include themify($themes) {
    background-color: themed('backgroundColorTertiary');
  }
}
