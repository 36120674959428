@use '../../styles/utils' as *;

.previewBanner {
  background-color: $color-error;
  box-sizing: border-box;
  color: $color-white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: $preview-banner-height;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  width: 100%;

  @include breakpoint-md {
    grid-template-columns: 1fr 2fr 1fr;
  }

  div {
    align-items: center;
    display: flex;
  }

  h5 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Open Sans', sans-serif;
    font-weight: 600;
  }
}

.previewMessage {
  display: none !important;
  font-size: 0.8rem;
  justify-content: center;
  text-align: center;

  @include breakpoint-md {
    display: flex !important;
  }
}

.previewExitButton {
  justify-content: right;

  button {
    border: 1px solid $color-white;
    font-size: 0.8rem;
    padding: 0.25rem;
  }

  button:hover {
    background-color: $color-white;
    color: $color-error;
  }
}

.previewBody {
  margin-top: 2.5rem;
}
