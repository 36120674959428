@use '../../styles/utils' as *;

.wrapper {
  padding-top: rem(15);
  padding-bottom: rem(15);
}

.copy {
  line-height: rem(24);
  font-size: rem(12);
}

.link {
  color: $color-blue;
  text-decoration: underline;
}
