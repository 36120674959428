@use '../../../styles/utils' as *;

.fieldSet {
  @include form-fieldset;
}

.fieldSetDisabled {
  opacity: $form-input-disabled-opacity;
}

.fieldSetLabel {
  @include form-input-label;
}

.fieldSetError {
  color: $color-error;
}

.noBottomSpacing {
  margin-bottom: 0;
}
