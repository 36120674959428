@use '../../styles/utils' as *;

.preLineWrap {
  white-space: pre-line;
}

.h1Hero {
  @include text-h1-hero;
}

.h1 {
  @include text-h1;
}

.h2 {
  @include text-h2;
}

.h3 {
  @include text-h3;
}

.h4 {
  @include text-h4;
}
