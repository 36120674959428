@use '../../styles/utils' as *;

.videoPreview {
  align-items: center;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include breakpoint-md {
    aspect-ratio: 16/12;
  }

  @include breakpoint-xl {
    aspect-ratio: 16/9;
  }
}

.backgroundTriangle {
  bottom: 0;
  fill: $color-lime;
  height: 110%;
  position: absolute;
  right: 0;
  width: initial;

  @include breakpoint-md {
    bottom: 0;
    height: 60%;
    position: absolute;
    right: 0;
    transform: none;
    width: initial;
  }

  &Themed {
    @extend .backgroundTriangle;

    @include themify($themes) {
      fill: themed('backgroundColor');
    }
  }
}

.playButton {
  align-items: center;
  background-color: $color-white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  user-select: none;
}

.outerRing {
  background-color: #{$color-white}#{'3d'};
  border-radius: 50%;
  height: rem(120);
  position: absolute;
  width: rem(120);
  z-index: 0;
}

.playIconButtonWrapper {
  align-items: center;
  background-color: $color-white;
  border-radius: 50%;
  display: flex;
  height: rem(106);
  justify-content: center;
  width: rem(106);
  z-index: 0;
}

.playIconWrapper {
  height: auto;
  width: 48px;
}

.playIconPad {
  margin-left: 6px;
  width: 48px;
}
