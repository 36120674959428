@use 'sass:color';
@use '../../../styles/utils' as *;

.breadcrumbs {
  background-color: color.adjust($color-slate, $alpha: -0.24);
  color: $color-white;
  padding: rem(12) 0;
}

.breadcrumbsContent {
  display: flex;
  justify-content: space-between;

  button {
    background-color: transparent;

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $color-white;
      fill: rgba($color-white, 50%);
    }
  }
}

.breadcrumbList {
  display: flex;

  li {
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      height: rem(12);
      margin: 0 rem(8);
      width: rem(8);
    }
  }
}
