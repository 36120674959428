@use '../core' as *;
@use 'sass:math';
@use 'sass:map';

$row-directions: 'row', 'row-reverse', 'column', 'column-reverse';
$align-items: 'flex-start', 'center', 'flex-end';

@mixin create-col-classes($modifier, $gridCols, $breakpoint) {
  @include create-mq($breakpoint) {
    @for $i from 0 through $gridCols + 1 {
      &[data-#{$modifier}='#{$i}'] {
        width: math.percentage(math.div($i, $gridCols));
      }
      &[data-offset-#{$modifier}='#{$i}'] {
        margin-left: math.percentage(math.div($i, $gridCols));
      }
      &[data-align-#{$modifier}='#{$i}'] {
        align-items: $i;
      }
    }

    @each $align in $align-items {
      &[data-align-#{$modifier}='#{$align}'] {
        align-items: #{$align};
      }

      &[data-justify-#{$modifier}='#{$align}'] {
        justify-content: #{$align};
      }
    }
  }
}

@mixin col-classes {
  @each $modifier, $value in $map-grid-props {
    @include create-col-classes($modifier, map.get($value, cols), map.get($value, breakpoint));
  }
}

@mixin create-row-classes($modifier, $breakpoint) {
  @include create-mq($breakpoint) {
    @each $direction in $row-directions {
      &[data-direction-#{$modifier}='#{$direction}'] {
        flex-direction: #{$direction};
      }
    }

    @each $align in $align-items {
      &[data-justify-#{$modifier}='#{$align}'] {
        justify-content: #{$align};
      }
    }
  }
}

@mixin row-classes {
  @each $modifier, $value in $map-grid-props {
    @include create-row-classes($modifier, map.get($value, breakpoint));
  }
}
