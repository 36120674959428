@use '../../styles/utils' as *;

.tableContainer {
  overflow-x: scroll;
  position: relative;
}

.table {
  min-width: rem(720);
  padding: rem(16) 0;

  table {
    width: 100%;
  }

  tr {
    border-bottom: 1px solid $color-light-slate;
  }

  tr:first-child {
    border-bottom: 1px solid $color-light-slate;

    p {
      @include base-paragraph-bold;

      text-align: left;
    }
  }

  tr td {
    background-color: $color-white;
  }

  tr td:first-child {
    background-color: $color-light-lime;
  }

  tr td:last-child {
    background-color: rgba($color-slate, 0.08);
  }

  tr td:first-child p {
    @include base-paragraph-bold;
  }

  // th represents a header in a table IF it has been enabled
  th {
    background-color: $color-lime;
    border-bottom: 1px solid $color-lime;
  }

  th,
  td {
    padding: rem(16);
    width: 33%;
  }

  th p,
  td p {
    margin: 0;
    text-align: left;
  }
}
