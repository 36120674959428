@use '../../../styles/utils' as *;

.desktopMenu {
  display: none;

  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }

  @include breakpoint-md {
    display: block;
  }
}

.desktopMenuList {
  display: flex;
  font-family: $heading-secondary-font-family;
  font-style: italic;

  // Desktop Menu List item text
  @include themify($themes) {
    color: themed('textColorSecondary');
  }
}

.desktopMenuListItem {
  @include transition-linear(background-color);

  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    @include themify($themes) {
      color: themed('textColor');
      background-color: themed('backgroundColor');
    }
  }
}

.desktopMenuListItemHover {
  div {
    opacity: 1;
    visibility: visible;
  }
}

.desktopMenuListItemActive {
  @include themify($themes) {
    color: themed('textColor');
    background-color: themed('backgroundColor');
  }
}

.desktopMenuListItemLink {
  a {
    display: block;
    padding: rem(12);
  }
}
