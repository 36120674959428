@use '../../styles/utils' as *;

.nextImageWrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    height: 100%;
    height: stretch;
    object-fit: cover;
    width: 100%;
  }
}

.contain {
  img {
    object-fit: contain;
  }
}

.loadingState {
  align-items: center;
  background-color: rgba($color-slate, 8%);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
}
