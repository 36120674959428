@use '../../../styles/utils' as *;

.textInput {
  @include form-field-spacing;
}

.input {
  @include form-input;
}

.label {
  @include form-input-label;
}

.assistiveText {
  @include form-field-assitive-text;
}

.fieldErrorMessage {
  @include form-field-error-message;
}
