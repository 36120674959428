@use '../../../styles/utils' as *;

.navLogo {
  margin: auto;
  width: rem(140);

  @include breakpoint-md {
    margin: 0;
  }

  svg {
    margin: rem(2) 0 0;
  }
}

.navLogoSmall {
  width: rem(103);
}
