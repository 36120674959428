@use '../../styles/utils' as *;

.footer {
  @include base-small-regular;

  margin-top: auto;
  text-align: center;

  @include breakpoint-md {
    text-align: left;
  }
}

.footerContent {
  background-color: $color-slate;
  color: $color-white;

  p {
    @include base-small-bold;
  }

  ul {
    list-style-type: none;

    li {
      margin: rem(8) 0;
    }
  }
}

.footerLogoWrapper {
  border-bottom: 2px solid rgba($color-white, 24%);
  padding: rem(24);

  @include breakpoint-md {
    padding: rem(24) 0;
  }

  @include breakpoint-xl {
    border-bottom: 0 none;
  }

  p {
    @include base-small-regular;

    font-family: $heading-secondary-font-family;
  }

  a[class^='ButtonStandard'] {
    display: flex;
    margin: 0 auto;
    max-width: fit-content;

    @include breakpoint-md {
      margin: 0;
    }

    &:hover {
      border-color: $color-lime;
      color: $color-lime;
    }
  }
}

.footerLogo {
  margin-bottom: rem(16);

  div {
    margin: rem(8) auto;

    @include breakpoint-md {
      margin: rem(8) 0;
    }
  }

  svg {
    width: rem(140);

    @include breakpoint-md {
      margin: 0;
    }
  }
}

.footerMenuSocial {
  display: inline-flex;
  padding-top: rem(16);

  @include breakpoint-md {
    margin-left: rem(-12);
  }

  span span {
    height: rem(22);
    width: rem(22);
  }

  span:hover,
  span:hover a:hover {
    fill: rgba($color-white, 50%);
  }
}

.footerMenuFirstColumn {
  padding: rem(24) 0 rem(12) 0;

  @include breakpoint-md {
    padding: rem(24) 0;
  }
}

.footerMenuSecondColumn {
  padding: rem(12) 0 rem(16) 0;

  @include breakpoint-md {
    padding: rem(24) 0;
  }
}

.footerBottomBar {
  background-color: $color-slate;
  border-top: 1px solid rgba($color-white, 24%);
  color: $color-white;
  padding: rem(24) 0;

  ul {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(8);

    @include breakpoint-md {
      justify-content: flex-start;
      padding: 0;
    }

    li {
      margin-right: rem(16);
    }
  }

  p {
    @include base-small-bold;

    @include breakpoint-md {
      text-align: right;
    }
  }
}
