@use '../../styles/utils' as *;

.matterportBlock {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.headingWrapper {
  margin-bottom: rem(8);

  &:empty {
    margin-bottom: 0;
  }
}

.textWrapper {
  margin-bottom: rem(32);

  @include breakpoint-xl {
    max-width: rem(588);
  }
}

.matterportIframe {
  height: 60vh;
  margin-bottom: rem(36);

  @include breakpoint-md {
    height: 65vh;
  }

  @include breakpoint-xl {
    height: 60vh;
    margin-bottom: rem(72);
  }
}
