@use '../core' as *;
@use 'sass:map';

@mixin create-visibility-classes($modifier, $breakpoint) {
  @include create-mq($breakpoint) {
    &[data-show-#{$modifier}='true'] {
      display: inherit;
    }
    &[data-show-#{$modifier}='false'] {
      display: none;
    }
  }
}

@mixin visibility-classes {
  @each $modifier, $value in $map-grid-props {
    @include create-visibility-classes($modifier, map.get($value, breakpoint));
  }
}
