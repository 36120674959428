@use '../../styles/utils' as *;

.header {
  background-color: $color-white;
  height: rem($header-height-mobile);
  position: sticky;
  top: 0;
  z-index: 10;

  @include breakpoint-md {
    height: rem($header-height-tablet);
  }

  @include breakpoint-lg {
    height: rem($header-height-desktop);
  }
}

.previewHeader {
  height: calc(rem($header-height-mobile) + $preview-banner-height);

  @include breakpoint-md {
    height: calc(rem($header-height-tablet) + $preview-banner-height);
  }

  @include breakpoint-lg {
    height: calc(rem($header-height-desktop) + $preview-banner-height);
  }
}

.headerTopBar {
  display: flex;

  @include breakpoint-md {
    width: auto;
  }

  > div {
    display: flex;
    height: rem($header-height-mobile);
    justify-content: space-between;
    width: 100%;

    @include breakpoint-md {
      height: rem($header-height-tablet);
    }

    @include breakpoint-lg {
      height: rem($header-height-mobile);
    }
  }
}

.headerTopBarInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.headerTopBarLogoWrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint-lg {
    align-items: center;
    flex-direction: row-reverse;
  }
}

.headerTopBarLogoInner {
  display: flex;

  @include breakpoint-lg {
    align-items: center;
    flex-direction: row-reverse;
  }
}

.headerTopBarLogoSlash {
  width: rem(36);
}

.headerTopBarLogoText {
  @include font-size(18 18);

  font-family: $heading-secondary-font-family;
  font-style: italic;
  font-weight: 500;
  white-space: nowrap;

  @include breakpoint-lg {
    @include font-size(32 38);
  }
}

.headerTopBarControls {
  align-items: center;
  display: flex;

  button:last-child {
    margin-left: rem(4);

    @include breakpoint-sm {
      margin-left: rem(8);
    }

    @include breakpoint-lg {
      margin-left: 0;
    }
  }
}
