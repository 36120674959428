@use '../../../styles/utils' as *;

.radioSelectionLabel {
  @include form-selection-label;
}

.radioSelectionLabelIcon {
  margin-right: rem(12);
  width: rem(16);
}

.radioSelectionDisabled {
  opacity: $form-input-disabled-opacity;
}

.radioSelectionInput {
  @include form-selection;
}

.fieldErrorMessage {
  @include form-field-error-message;

  display: block;
}
