@use '../../styles/utils' as *;

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include themify($themes) {
    background-color: themed('pageBackgroundColor');
  }
}
