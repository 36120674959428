@use '../../styles/utils' as *;

.galleryShowcase {
  display: flex;
  flex-direction: column;

  @include breakpoint-xl {
    flex-direction: row;
  }
}

.featuredImage {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: rem(194);
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include breakpoint-sm {
    height: rem(300);
  }

  @include breakpoint-md {
    height: rem(436);
  }

  div {
    height: inherit;
    width: inherit;
  }
}

.snippets {
  display: grid;
  gap: rem(2);
  grid-template: 1fr / 1fr 1fr 1fr;
  margin-left: rem(0);
  margin-top: rem(2);

  @include breakpoint-xl {
    grid-template: 1fr 1fr 1fr / 1fr;
    margin-left: rem(2);
    margin-top: 0;
    width: fit-content;
  }

  img {
    aspect-ratio: 1/1;
  }
}

.snippetSquare {
  cursor: pointer;
  height: rem(88);

  @include themify($themes) {
    background-color: themed('backgroundColor');
  }

  @include breakpoint-sm {
    height: rem(120);
  }

  @include breakpoint-md {
    height: rem(144);
  }

  @include breakpoint-xl {
    aspect-ratio: 1/1;

    img {
      width: 100%;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.snippetViewAll {
  align-items: center;
  display: flex;
  justify-content: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    -webkit-margin-after: 0 !important;
    margin-block-end: 0 !important;
  }

  @include themify($themes) {
    color: themed('textColor');
  }
}

.galleryOverlay {
  align-items: center;
  background-color: $color-slate;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
}

.galleryContent {
  background: none;
  border: 0;
  border-radius: 0;
  height: 100%;
  inset: 0;
  overflow: hidden;
  padding: 0;
  position: unset;
  width: 100%;
}
