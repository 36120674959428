@use '../../styles/utils' as *;

.headOfficePhoneNumber {
  margin-bottom: rem(24);
  text-align: center;

  a {
    color: $color-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}
