@use '../../styles/utils' as *;

$sm-vmin: 80vmin;
$md-vmin: 85vmin;
$xl-vmin: 90vmin;
$xxl-vmin: 95vmin;

.galleryModal {
  :global {
    .slider-wrapper {
      aspect-ratio: 16/9;
      width: calc(100vw - 48px) !important;

      @include breakpoint-sm {
        width: $sm-vmin !important;
      }

      @include breakpoint-md {
        width: $md-vmin !important;
      }

      @include breakpoint-xl {
        width: $xl-vmin !important;
      }

      @include breakpoint-xxl {
        width: $xxl-vmin !important;
      }
    }

    .slide div[data-next-image] {
      aspect-ratio: 16/9;
      position: relative;
    }

    .slide:not(.selected) {
      border: 0.5px solid transparent;
    }
  }
}

.carouselContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.carouselWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.carousel {
  padding: 0 rem(24);
  width: calc(100vw - 48px);

  @include breakpoint-sm {
    width: $sm-vmin;
  }

  @include breakpoint-md {
    width: $md-vmin;
  }

  @include breakpoint-xl {
    width: $xl-vmin;
  }

  @include breakpoint-xxl {
    width: $xxl-vmin;
  }
}

.caption {
  @include base-small-regular;

  color: $color-white;
  height: rem(40);
  margin-top: rem(24);
  overflow: hidden;
  padding: 0 rem(24);
  text-align: center;
  text-overflow: ellipsis;

  @include breakpoint-sm {
    margin-top: rem(12);
    width: $sm-vmin;
  }

  @include breakpoint-md {
    margin-top: rem(12);
    width: $md-vmin;
  }

  @include breakpoint-xl {
    margin-bottom: rem(96);
    width: $xl-vmin;
  }

  @include breakpoint-xxl {
    margin-bottom: rem(120);
    width: $xxl-vmin;
  }
}

.thumbnails {
  bottom: rem(24);
  display: none;
  justify-content: center;
  overflow: auto;
  position: fixed;
  width: calc(100vw - 48px);

  @media screen and (orientation: portrait) and (max-aspect-ratio: 3 / 4) {
    display: flex;
  }

  @include breakpoint-sm {
    display: flex;
    width: $sm-vmin;

    @media screen and (orientation: landscape) and (min-aspect-ratio: 4 / 3) {
      display: none;
    }
  }

  @include breakpoint-md {
    width: $md-vmin;

    @media screen and (orientation: landscape) and (min-aspect-ratio: 16 / 9) {
      display: none;
    }
  }

  @include breakpoint-lg {
    display: flex;
  }

  @include breakpoint-xl {
    display: flex;
    width: $xl-vmin;
  }

  @include breakpoint-xxl {
    width: $xxl-vmin;
  }
}

.thumb {
  margin-right: rem(8);
  opacity: 0.3;

  &:last-child {
    margin-right: 0;
  }

  img {
    aspect-ratio: 1/1;
    height: rem(80);
    width: rem(80);
  }
}

.selected {
  opacity: 1;
}

.thumbsWrapper {
  display: flex;
  justify-content: center;
}

.overflowing {
  justify-content: flex-start;
  overflow: auto;
}

.buttonLeft {
  left: rem(24);
  position: fixed;

  @include breakpoint-xl {
    left: rem(64);
  }

  @include breakpoint-xxl {
    left: rem(120);
  }

  @media screen and (orientation: portrait) {
    display: none;
  }
}

.buttonRight {
  position: fixed;
  right: rem(24);

  @include breakpoint-xl {
    right: rem(64);
  }

  @include breakpoint-xxl {
    right: rem(120);
  }

  @media screen and (orientation: portrait) {
    display: none;
  }
}

.mobileControls {
  display: none;
  margin-top: rem(12);

  @include breakpoint-sm {
    margin-top: rem(24);
  }

  button {
    margin: 0 rem(16);
  }

  @media screen and (orientation: portrait) {
    display: flex;
    justify-content: center;
  }
}

.closeButton {
  position: fixed;
  right: rem(24);
  top: rem(24);
}

.windowsScrollbar {
  overflow: overlay;

  /* Firefox */
  scrollbar-color: $color-lime transparent;
  scrollbar-width: auto;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-lime, 16%);
    border: 2px solid transparent;
    border-radius: 6px;

    &:hover {
      background-color: $color-lime;
    }
  }
}
