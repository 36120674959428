.linkWrapper {
  user-select: none;
}

.linkAnchor {
  cursor: pointer;
}

.linkWrapperHover {
  a:hover {
    text-decoration: underline;
  }
}
