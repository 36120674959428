@use '../../../styles/utils' as *;
@include button-pill-classes;

$icon-size: rem(15);

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: fit-content;
}

.buttonContainerIconLeft {
  flex-direction: row-reverse;
}

.icon {
  display: flex;
  height: $icon-size;
  margin: 0 rem(2) 0 rem(6);
  width: $icon-size;
}

.iconLeft {
  margin: 0 rem(6) 0 rem(2);
}

.iconSolo {
  margin: 0 rem(6);
}

.disabled {
  border-color: rgba($color-slate, 0.24);
  color: rgba($color-slate, 0.24);
  cursor: auto;
  fill: rgba($color-slate, 0.24);
  pointer-events: none;
}

.bounceIcon {
  animation: bounce-vertical 8s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite both;
}

@keyframes bounce-vertical {
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-3px);
  }

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(3px);
  }
}
