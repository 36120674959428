@use '../../../styles/utils' as *;

.subNavMenu {
  margin-left: rem(24);
  width: 100%;

  @include breakpoint-lg {
    @include transition-linear(opacity);

    background-color: $color-white;
    filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 25%));
    left: 0;
    margin-left: 0;
    opacity: 0;
    padding: rem(16) rem(23) 0 rem(16);
    position: absolute;
    top: 40px;
    visibility: hidden;
    width: max-content;
    z-index: 1;
  }
}

.subNavNotch {
  display: none;

  @include breakpoint-lg {
    background: $color-white;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    display: block;
    height: 8px;
    position: absolute;
    top: -7px;
    width: 16px;
  }
}

.subNavList {
  color: $color-white;

  @include breakpoint-lg {
    color: $color-slate;

    li {
      margin-bottom: rem(16);
      padding: 0;
    }
  }

  li {
    padding: rem(8) 0;

    &:last-child {
      padding-bottom: rem(16);
    }
  }
}
