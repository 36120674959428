@use '../../styles/utils' as *;

.modalOverlay {
  align-items: center;
  background-color: #{$color-slate}#{'7F'};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.modalContent {
  background: $color-white;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  inset: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    height: calc(100% - rem($header-height-tablet));
    top: rem($header-height-tablet);
    width: 100%;
  }

  @include breakpoint-lg {
    height: auto;
    max-height: 80%;
    max-width: rem($breakpoint-md);
    position: unset;
  }
}

.modalHeader {
  background-color: $color-lime;
  display: flex;
  justify-content: space-between;
  padding: rem(16) rem(24);

  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }
}

.modalHeaderError {
  background-color: $color-error !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-white;
  }

  svg {
    fill: $color-white;
  }
}

.modalBody {
  overflow: auto;
  padding: rem(24);
}
