@use '../../styles/utils' as *;

.inlineMediaAssetLink,
.inlineEntryLink {
  color: $color-blue;

  path {
    fill: $color-blue;
  }

  &:hover {
    border-color: $color-slate;
    color: $color-slate;
    text-decoration: underline;

    path {
      fill: $color-slate;
    }
  }

  &Slate {
    color: $color-slate;

    path {
      fill: $color-slate;
    }
  }
}

.iconSvgWrapper {
  margin-left: auto;
  white-space: nowrap;

  svg {
    height: rem(12);
    margin: 0 rem(4) rem(4) rem(4);
    vertical-align: middle;
    width: rem(12);
  }
}

.table {
  overflow: auto;
}

.mediaAssetLink {
  a {
    text-decoration: none;
  }
}
