@use '../../../styles/utils' as *;

.communityEventRSVPForm {
  margin-bottom: rem(40);
}

.submissionMessage {
  padding: rem(32);
}

.success {
  background-color: $color-light-lime;
  padding: rem(32);
}

.error {
  background-color: $color-light-error;
}
