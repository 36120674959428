@use '../../styles/utils' as *;

.loadingState {
  height: auto;
  max-width: rem(40);
  width: 100%;

  svg {
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-timing-function: linear;
    transition-property: transform;
    will-change: transform;
  }

  .variant {
    animation-duration: 1500ms;
    animation-timing-function: cubic-bezier(0.42, 0.82, 0.07, 1.45);
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
