@use '../../styles/utils' as *;

.largeRegular {
  p,
  li {
    @include base-large-regular;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.75rem;
  }
}

.largeSemibold {
  p,
  li {
    @include base-large-semibold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.75rem;
  }
}

.largeBold {
  p,
  li {
    @include base-large-bold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.75rem;
  }
}

.paragraphRegular {
  p,
  li {
    @include base-paragraph-regular;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.75rem;
  }
}

.paragraphSemibold {
  p,
  li {
    @include base-paragraph-semibold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.25rem;
  }
}

.paragraphBold {
  p,
  li {
    @include base-paragraph-bold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1rem;
  }
}

.smallRegular {
  p,
  li {
    @include base-small-regular;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.25rem;
  }
}

.smallSemibold {
  p,
  li {
    @include base-small-semibold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.25rem;
  }
}

.smallBold {
  p,
  li {
    @include base-small-bold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.25rem;
  }
}

.smallestRegular {
  p,
  li {
    @include base-smallest-regular;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1rem;
  }
}

.smallestSemibold {
  p,
  li {
    @include base-smallest-semibold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1rem;
  }
}

.smallestBold {
  p,
  li {
    @include base-smallest-bold;
  }

  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1rem;
  }
}

.content {
  // Block Elements
  p,
  ul,
  ol {
    margin-bottom: 1rem;
  }

  h1 {
    @include text-h1;

    margin-bottom: 1.75rem;
  }

  h2 {
    @include text-h2;

    margin-bottom: 1.75rem;
  }

  h3 {
    @include text-h3;

    margin-bottom: 1.75rem;
  }

  h4 {
    @include text-h4;

    margin-bottom: 1.75rem;
  }

  h6 {
    @include base-smallest-regular;

    margin-block-end: rem(16);
  }

  ul p,
  ol p {
    margin-bottom: 0;
  }

  ul {
    list-style-type: disc;
    margin-left: rem(16);
    padding-left: rem(8);
  }

  ol {
    list-style-type: decimal;
    margin-left: rem(16);
    padding-left: rem(8);
  }

  li {
    padding-left: rem(8);
  }

  blockquote {
    padding: rem(40) 0 rem(24);

    hr {
      border-color: rgba($color-slate, 0.08);
      border-style: solid;
      color: rgba($color-slate, 0.08);
    }

    p {
      @include text-h4;

      padding: rem(40) 0 rem(24);
    }
  }

  .mediaContent {
    padding: rem(24) 0 rem(40);
    position: relative;
  }

  .mediaContentImage {
    margin: rem(40) 0;
    padding-top: 66.6666%;
  }

  > :last-child {
    margin-bottom: 0;
  }

  // Inline Elements
  a {
    @include transition-link(text-decoration);

    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }
}
