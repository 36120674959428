@use '../../styles/utils/' as *;
@include color-classes('fill');

.icon {
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    fill: unset;

    g,
    path,
    circle {
      @include transition-ease-out(fill stroke color fill-opacity);
    }
  }
}

.fluid {
  > svg {
    height: 100%;
    width: 100%;
  }
}
