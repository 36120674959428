@use '../../../styles/utils' as *;

.textAreaInput {
  @include form-field-spacing;
}

.textarea {
  @include form-input;

  min-height: rem(144);
  resize: none;
}

.label {
  @include form-input-label;
}

.assistiveText {
  @include form-field-assitive-text;
}

.fieldErrorMessage {
  @include form-field-error-message;
}
