@use '../../../styles/utils' as *;

.checkboxLabel {
  @include form-radio-checkbox-label;
}

.checkboxLabel + .checkboxLabel {
  margin-top: 10px;
}

.checkboxLabelDisabled,
.checkboxLabelReadOnly {
  opacity: $form-input-disabled-opacity;
}

.checkboxInput {
  @include form-checkbox;
}

.fieldErrorMessage {
  @include form-field-error-message;
}
