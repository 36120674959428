@use '../../../styles/utils' as *;

.siteSearch {
  display: flex;
  flex-grow: 1;
  margin-left: rem(12);
  position: relative;
}

.siteSearchInput {
  @include base-paragraph-regular;

  appearance: none;
  border: 2px solid transparent;
  border-radius: rem(40);
  display: flex;
  flex-grow: 1;
  font-family: $form-input-font-family;
  height: rem(44);
  padding: 0 rem(16);
  width: 100%;

  @include breakpoint-lg {
    min-width: rem(212);
  }

  @include breakpoint-xl {
    min-width: rem(282);
  }

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border: 2px solid $color-lime;
  }
}

.siteSearchInputLight {
  background-color: rgba($color-white, 8%);
  color: rgba($color-white, 50%);
}

.siteSearchInputDark {
  background-color: rgba($color-slate, 8%);
}

.siteSearchInputIcon {
  position: absolute;
  right: rem(7);
  top: rem(6);
}

.siteSearchInputIcon button {
  &:hover,
  &:focus {
    background-color: rgba($color-white, 8%);
    fill: $color-white;

    @include breakpoint-lg {
      background-color: rgba($color-slate, 0.08);
      border-color: $color-slate;
      fill: $color-slate;
    }
  }
}
