@use '../../../styles/utils' as *;

.selectInput {
  @include form-field-spacing;
}

.select {
  @include form-input;
  @include form-select;
}

.label {
  @include form-input-label;
}

.inputError {
  background-color: $form-input-background-color-error !important;
  background-image: $form-select-chevron-error;
  border-color: $form-input-border-color-error !important;
  color: $form-input-color-error;

  &:hover {
    background-color: $form-input-background-color-error !important;
  }
}

.assistiveText {
  @include form-field-assitive-text;
}

.fieldErrorMessage {
  @include form-field-error-message;
}
