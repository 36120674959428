@use '../../../styles/utils' as *;

.communityLeadForm {
  margin-bottom: rem(40);
}

.contactForm {
  select,
  input[name='postCode'] {
    width: 100%;

    @include breakpoint-xl {
      width: 50%;
    }
  }
}
