@use '../../../styles/utils' as *;
@use 'sass:math';

.column {
  @include col-classes;
  @include visibility-classes;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 rem(math.div($grid-gutter-horizontal-mobile, 2));
  width: 100%;

  @include breakpoint-lg {
    padding: 0 rem(math.div($grid-gutter-horizontal-desktop, 2));
  }
}
