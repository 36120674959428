@use '../../../styles/utils' as *;

.webToCaseForm {
  margin-bottom: rem(40);
}

.reCaptchaFail {
  padding-top: rem(15);
  color: $color-error;
}


