@use '../core' as *;

// Mixin for Button colors
@mixin button-color($background-color, $color, $border-color) {
  background-color: $background-color;
  border: 2px solid $border-color;
  color: $color;
  fill: $color;
}

// Button classes
@mixin button-classes {
  .button {
    @include transition-link(background-color border-color color);
    @include font-size(14 24);

    align-items: center;
    border-radius: rem(32);
    cursor: pointer;
    display: inline-flex;
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 600;
    gap: rem(4);
    letter-spacing: -0.01em;
    padding: rem(8) rem(24);
    text-align: center;
    white-space: nowrap;

    &:hover {
      @include button-color($color-slate, $color-white, $color-white);
    }

    &:disabled {
      @include button-color(
        rgba($color-slate, 0.08),
        rgba($color-slate, 0.24),
        rgba($color-slate, 0)
      );

      pointer-events: none;
    }
  }

  .large {
    @include font-size(16 24);

    padding: rem(12) rem(32);
  }

  .medium {
    @include font-size(14 24);

    padding: rem(8) rem(24);
  }

  .small {
    @include font-size(14 24);

    padding: rem(4) rem(16);
  }

  // ButtonIcon and sizes
  .buttonIcon {
    @include transition-link(background-color border-color color);

    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    white-space: nowrap;

    &:hover {
      @include button-color($color-slate, $color-white, $color-white);
    }

    &:disabled {
      @include button-color(
        rgba($color-slate, 0.08),
        rgba($color-slate, 0.24),
        rgba($color-slate, 0)
      );

      pointer-events: none;
    }
  }

  .buttonIconXXLarge {
    height: rem(48);
    padding: rem(12);
    width: rem(48);
  }

  .buttonIconXLarge {
    height: rem(40);
    padding: rem(8);
    width: rem(40);
  }

  .buttonIconLarge {
    height: rem(36);
    padding: rem(8);
    width: rem(36);
  }

  .buttonIconMedium {
    height: rem(32);
    padding: rem(6);
    width: rem(32);
  }

  .buttonIconSmall {
    height: rem(28);
    padding: rem(6);
    width: rem(28);
  }

  .buttonIconXSmall {
    height: rem(24);
    padding: rem(4);
    width: rem(24);
  }

  .buttonLinkIconXXLarge {
    height: rem(48);
    width: rem(48);

    span {
      height: rem(20);
      width: rem(20);
    }
  }

  .buttonLinkIconXLarge {
    height: rem(40);
    width: rem(40);

    span {
      height: rem(20);
      width: rem(20);
    }
  }

  .buttonLinkIconLarge {
    height: rem(36);
    width: rem(36);

    span {
      height: rem(16);
      width: rem(16);
    }
  }

  .buttonLinkIconMedium {
    height: rem(32);
    width: rem(32);

    span {
      height: rem(16);
      width: rem(16);
    }
  }

  .buttonLinkIconSmall {
    height: rem(28);
    width: rem(28);

    span {
      height: rem(12);
      width: rem(12);
    }
  }

  .buttonLinkIconXSmall {
    height: rem(24);
    width: rem(24);

    span {
      height: rem(12);
      width: rem(12);
    }
  }

  .primaryButton {
    @include themify($themes) {
      background-color: themed('backgroundColorTertiary');
      border: 2px solid themed('backgroundColorTertiary');
      color: themed('textColorSecondary');
    }

    &:hover {
      @include button-color($color-slate, $color-white, $color-white);
    }

    &:disabled {
      @include button-color(
        rgba($color-slate, 0.08),
        rgba($color-slate, 0.24),
        rgba($color-slate, 0)
      );

      pointer-events: none;
    }
  }

  .secondaryButton {
    @include themify($themes) {
      background-color: themed('backgroundColorSecondary');
      border: 2px solid themed('backgroundColorSecondary');
      color: themed('textColorSecondary');
    }

    &:hover {
      @include button-color($color-slate, $color-white, $color-white);
    }

    &:disabled {
      @include button-color(
        rgba($color-slate, 0.08),
        rgba($color-slate, 0.24),
        rgba($color-slate, 0)
      );

      pointer-events: none;
    }
  }

  .whiteButton {
    @include button-color($color-white, $color-black, $color-white);
  }

  .whiteOutlineButton {
    @include button-color(transparent, $color-white, $color-white);
  }

  .blackButton {
    @include button-color($color-black, $color-white, $color-black);
  }

  .blackOutlineButton {
    @include button-color(transparent, $color-black, $color-black);
  }

  .slateButton {
    @include button-color($color-slate, $color-white, $color-slate);
  }

  .slateOutlineButton {
    @include button-color(transparent, $color-slate, $color-slate);
  }

  .lightSlateButton {
    @include button-color($color-light-slate, $color-slate, $color-light-slate);
  }

  .lightSlateOutlineButton {
    @include button-color(transparent, $color-light-slate, $color-light-slate);
  }

  .limeButton {
    @include button-color($color-lime, $color-slate, $color-lime);
  }

  .limeOutlineButton {
    @include button-color(transparent, $color-lime, $color-lime);
  }

  .lightLimeButton {
    @include button-color($color-light-lime, $color-slate, $color-light-lime);
  }

  .lightLimeOutlineButton {
    @include button-color(transparent, $color-light-lime, $color-light-lime);
  }

  .yellowButton {
    @include button-color($color-yellow, $color-slate, $color-yellow);
  }

  .yellowOutlineButton {
    @include button-color(transparent, $color-yellow, $color-yellow);
  }

  .lightYellowButton {
    @include button-color(
      $color-light-yellow,
      $color-slate,
      $color-light-yellow
    );
  }

  .lightYellowOutlineButton {
    @include button-color(
      transparent,
      $color-light-yellow,
      $color-light-yellow
    );
  }

  .orangeButton {
    @include button-color($color-orange, $color-slate, $color-orange);
  }

  .orangeOutlineButton {
    @include button-color(transparent, $color-orange, $color-orange);
  }

  .lightOrangeButton {
    @include button-color(
      $color-light-orange,
      $color-slate,
      $color-light-orange
    );
  }

  .lightOrangeOutlineButton {
    @include button-color(
      transparent,
      $color-light-orange,
      $color-light-orange
    );
  }

  .lilacButton {
    @include button-color($color-lilac, $color-slate, $color-lilac);
  }

  .lilacOutlineButton {
    @include button-color(transparent, $color-lilac, $color-lilac);
  }

  .lightLilacButton {
    @include button-color($color-light-lilac, $color-slate, $color-light-lilac);
  }

  .lightLilacOutlineButton {
    @include button-color(transparent, $color-light-lilac, $color-light-lilac);
  }

  .blueButton {
    @include button-color($color-blue, $color-slate, $color-blue);
  }

  .blueOutlineButton {
    @include button-color(transparent, $color-blue, $color-blue);
  }

  .lightBlueButton {
    @include button-color($color-light-blue, $color-slate, $color-light-blue);
  }

  .lightBlueOutlineButton {
    @include button-color(transparent, $color-light-blue, $color-light-blue);
  }

  .redButton {
    @include button-color($color-error, $color-slate, $color-error);
  }

  .redOutlineButton {
    @include button-color(transparent, $color-error, $color-error);
  }

  .lightRedButton {
    @include button-color($color-light-error, $color-slate, $color-light-error);
  }

  .lightRedOutlineButton {
    @include button-color(transparent, $color-light-error, $color-light-error);
  }
}

// Mixin for ButtonPill colors
@mixin button-pill-color($color) {
  border-bottom: 2px solid $color;
  color: $color;
  fill: $color;
}

// ButtonPill classes
@mixin button-pill-classes {
  .buttonPill {
    @include transition-link(border-color fill color);
    @include font-size(14 24);

    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 700;
    gap: rem(4);
    letter-spacing: -0.01em;
    line-height: rem(24);
    padding: 0 0 rem(4);
    text-align: center;
    white-space: nowrap;

    &:hover {
      @include button-pill-color($color-slate);
    }

    &:disabled {
      @include button-pill-color(rgba($color-slate, 0.24));

      pointer-events: none;
    }
  }

  .primaryButtonPill {
    @include themify($themes) {
      border: 2px solid themed('backgroundColor');
      color: themed('textColor');
    }

    &:hover {
      @include button-pill-color($color-slate);
    }

    &:disabled {
      @include button-pill-color(rgba($color-slate, 0.08));

      pointer-events: none;
    }
  }

  .secondaryButtonPill {
    @include themify($themes) {
      border: 2px solid themed('backgroundColorSecondary');
      color: themed('textColorSecondary');
    }

    &:hover {
      @include button-pill-color($color-slate);
    }

    &:disabled {
      @include button-pill-color(rgba($color-slate, 0.08));

      pointer-events: none;
    }
  }

  .whiteButtonPill {
    @include button-pill-color($color-white);
  }

  .blackButtonPill {
    @include button-pill-color($color-black);
  }

  .slateButtonPill {
    @include button-pill-color($color-slate);
  }

  .lightSlateButtonPill {
    @include button-pill-color($color-light-slate);
  }

  .limeButtonPill {
    @include button-pill-color($color-lime);
  }

  .lightLimeButtonPill {
    @include button-pill-color($color-light-lime);
  }

  .yellowButtonPill {
    @include button-pill-color($color-yellow);
  }

  .lightYellowButtonPill {
    @include button-pill-color($color-light-yellow);
  }

  .orangeButtonPill {
    @include button-pill-color($color-orange);
  }

  .lightOrangeButtonPill {
    @include button-pill-color($color-light-orange);
  }

  .lilacButtonPill {
    @include button-pill-color($color-lilac);
  }

  .lightLilacButtonPill {
    @include button-pill-color($color-light-lilac);
  }

  .blueButtonPill {
    @include button-pill-color($color-blue);
  }

  .lightBlueButtonPill {
    @include button-pill-color($color-light-blue);
  }
}
